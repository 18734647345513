<template>
  <div style="margin: -15px -15px;background-color: #f0f2f5;">
    <!-- 日期查询框 -->
    <div class="datePicker">
      <div class="date">
        状态：
        <a-radio-group v-model="status" @change="searchBtn">
          <a-radio-button :value="1">
            开启
          </a-radio-button>
          <a-radio-button :value="0">
            关闭
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="date">
        标签名称：
        <a-input style="width: 180px"  placeholder="请输入" v-model="searchName" @keyup.enter="searchBtn"></a-input>
      </div>
      <a-button type="primary" @click="searchBtn">查询</a-button>
      <a-button style="margin-left: 15px" @click="returnBtn">重置</a-button>
    </div>

    <!-- table -->
    <div class="table">
      <a-button style="margin-bottom: 10px" type="primary" @click="addBtn">新增</a-button>
      <a-table :columns="tableColumns" :pagination="false" :data-source="tableData"
               :row-key="(record) => record.id">
        <span slot="status" slot-scope="text,record">
          <a-switch
              :defaultChecked="record.status=== 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
                    @click="switchOpen(record.id,record.status === 1? 0 : 1)" />
        </span>
        <span slot="open" slot-scope="text,record">
          <a @click="editBtn(record)">
            编辑
          </a>&nbsp;
          <a-popconfirm
              title="是否确认删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delBtn(record)"
          >
            <a >删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <!-- 分页 -->
      <div style="background-color: #fff;width:100%;height: 100px;">
        <a-pagination style="float: right;margin-top: 25px;" show-quick-jumper show-size-changer :page-size.sync="pagination.pageSize"
                      @change="pageChange" @showSizeChange="pageChange"  v-model="pagination.current" :total=pagination.total
        />
      </div>
    </div>






    <a-modal  v-model="formShow" :title="type==='add'?'新增':'编辑'" @ok="formOk">
      <a-form-model v-bind="layout" :rules="rules"  :model="form"  ref="addItem" >
        <a-form-model-item  >
          <span slot="label" ><span style="color: red">*</span>标签名称</span>
          <a-input placeholder="请输入" v-model="form.labelContent"/>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status">
          <a-switch :checked="form.status=== 1 ? true:false" @click="form_switch(form.status === 1? 0 : 1)"
                    checked-children="开" un-checked-children="关"  />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </div>
</template>

<script>
import {delCase_tags, getCase_tags, postCase_tags, putCase_tags} from "@/service/caseClip_api";

export default {
  name: "caseClip_tags",
  data(){
    return{
      formShow:false,
      type:'add',
      form:{
        status:1,
        labelContent:'',
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      rules:{
        labelContent: [{ required: false, message: '请输入名称', }],
        status: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      tableData: [],  //用于列表数据
      searchName: '' , //传输入框的数据
      pagination: {   //用于分页
        total: 0,
        current: 1,
        pageSize: 10,
      },
      status:1,
      tableColumns:[
        {
          title: '标签名称',
          dataIndex: 'labelContent',
          // scopedSlots: { customRender: 'titles' },
          // width: '40%'
        },
        {
          title: '被添加人数',
          dataIndex: 'quote',
          align:'center'
          // width: '20%'
        },
        {
          title: '使用次数',
          dataIndex: 'count',
          align:'center'
          // width: '20%'
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '创建人',
          dataIndex: 'name',
          // width: '20%'
        },
        {
          title: '标签创建时间',
          dataIndex: 'createdTime',
          // width: '20%'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'open' },
        },
      ],
    }
  },
  mounted() {
    this.getTags()
  },
  methods:{
    async getTags(){

      const data={
        status:this.status,
        content:this.searchName,
        pageNum:this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      const info =await getCase_tags(data)
      if (info.code===0){
        this.pagination.total=info.data.total;
        this.tableData=info.data.list;
      }
    },
    async switchOpen(id,open){
      const edit = await putCase_tags(id,'',open)
      if (edit.code===0){
        this.$message.success(edit.message)
        this.getTags()
      }else {
        this.$message.error(edit.message)
      }

    },
    returnBtn() {
      this.searchName = ''  //输入框
      this.pagination.current=1;
      this.getTags()
    },
    searchBtn(){
      this.pagination.current=1;
      this.getTags()
    },
    pageChange(page_no, page_size){
      this.pagination.current = page_no;
      this.pagination.pageSize = page_size;
      this.getTags()
    },
    editBtn(row){
      this.$set(this,'form',JSON.parse(JSON.stringify(row)))
      this.type='edit';
      this.formShow=true;
    },
    addBtn(){
      this.form={
        status: 1,
        labelContent: ''
      }
      this.type='add'
      this.formShow=true
    },
    async delBtn(row){
      const del =await delCase_tags(row.id)
      if (del.code===0){
        this.$message.success(del.message)
        this.getTags()
      }else {
        this.$message.error(del.message)
      }
    },
    async formOk(){
      var reg = /^\s+$/g
      if (this.form.labelContent==='' ||reg.test(this.form.labelContent)){
        this.$message.error('输入有空~')
        return false;
      }
      switch (this.type) {
            case 'add':
              const add =await postCase_tags(this.form.labelContent,this.form.status)
              if (add.code===0){
                this.$message.success(add.message)
                this.getTags()
                this.formShow=false;
              }else {
                this.$message.error(add.message)
              }
              break;
            case 'edit':
              const edit =await putCase_tags(this.form.id,this.form.labelContent,this.form.status)
                if (edit.code===0){
                  this.$message.success(edit.message)
                  this.getTags()
                  this.formShow=false;
                }else {
                  this.$message.error(edit.message)
                }
              break;
          }
    },
    form_switch(checked){
      this.form.status = checked
    },
  },

}
</script>

<style lang="scss" scoped>
.datePicker{
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.date {
  /* margin: auto; */
  margin: 0 20px 0 10px;
  font-size: 15px;
  font-weight: 500;
  color:#272727;
}
.table{

  padding: 20px;
  background-color: #fff;
}

</style>